import './Login.scss';
import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import plusLogo from '../../assets/plus_logo_big.png';
import Context from '../../context';
import Download from '../download/Download';

function Login (props, context) {
  const { t } = useTranslation();
  const usernameRef = useRef();
  const passwordRef = useRef();
  const {login, showLogin, hideLogin, redirect} = useContext(Context);

  const onLogin = async () => {
    const username = usernameRef.current.value;
    const password = passwordRef.current.value;
    if (username && password) {
      await login(username, password);
      hideLogin();
      redirect('/user');
    }
  };

  const getReg = () => <div className="reg">
    <h1><img src={plusLogo}/><span>{t('reg_title')}</span></h1>
    <form>
      <p>{t('reg_tips')}</p>
      <Download/>
    </form>
    <p className="change"><a href="javascript:void(0);" onClick={() => showLogin()}>{t('btn_login')}</a></p>
  </div>

  const getLogin = () => <>
    <h1><img src={plusLogo}/><span>{t('login_title')}</span></h1>
    <form>
      <ul>
        <li>
          <label>{t('login_label_username')}</label>
          <input ref={usernameRef} type="text" />
        </li>
        <li>
          <label>{t('login_label_password')}</label>
          <input ref={passwordRef} type="password" />
        </li>
      </ul>
      <button type="button" onClick={onLogin}>{t('btn_login')}</button>
      <p className="change"><a href="javascript:void(0);" onClick={() => showLogin(true)}>{t('btn_reg')}</a></p>
    </form>
  </>

  return (
    <div className="widget login">
      {props.isReg ? getReg() : getLogin()}
    </div>
  );
}

export default Login;