import './Header.scss';
import React, { useContext, useState } from 'react';
import smallLogo from '../../assets/logo_s@2x.png';
import smallLogoWhite from '../../assets/logo_s_w@2x.png';
import Wrapper from '../wrapper/Wrapper';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import darrIcon from '../../assets/darr.svg';
import accountIcon from '../../assets/account.svg';
import languageIcon from '../../assets/language.svg';
import { changeLanguage, languages } from '../../i18n/i18n';
import Context from '../../context';
// import events, { UI_SHOW_LOGIN } from '../../events';

function Header(props) {
  const {user, showLogin} = useContext(Context);
  const {t} = useTranslation();
  const logoSrc = props.dark ? smallLogoWhite : smallLogo;

  return (
    <header className={`widget header ${props.dark ? 'dark' : ''}`}>
      <Wrapper>
        <ul className="nav">
          <li><Link to="/"><img className="logo" src={logoSrc} /></Link></li>
          <li><NavLink exact to="/">{t('nav_home')}</NavLink></li>
          <li><NavLink exact to="/support">{t('nav_support')}</NavLink></li>
          {/* <li><NavLink exact to="/plus">OneClick<sup>+</sup></NavLink></li> */}
        </ul>
        <ul className="nav extra">
          <li className="language">
            <a href="javascript:void(0);"><ReactSVG className="icon" src={languageIcon}/><span>{t('lng')}</span><ReactSVG className="arr" src={darrIcon}/></a>
            <ul className="menu">
              <li onClick={() => changeLanguage(languages.en_us)}>English</li>
              <li onClick={() => changeLanguage(languages.zh_cn)}>简体中文</li>
              <li onClick={() => changeLanguage(languages.zh_tw)}>繁體中文</li>
            </ul>
          </li>
          {/* <li className="account">
            {
              user ?
              <NavLink to="/user"><ReactSVG className="icon" src={accountIcon}/>{user.email}</NavLink>
              :
              <a href="javascript:void(0)" onClick={() => {showLogin()}}><ReactSVG className="icon" src={accountIcon}/>{t('btn_login')}</a>
            }
          </li> */}
        </ul>
      </Wrapper>
    </header>
  );
}

export default Header;
