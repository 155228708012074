import './Support.scss';
import React from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import Wrapper from '../../components/wrapper/Wrapper';
import Page from '../../components/page/Page';

import { Trans, useTranslation } from 'react-i18next';
import { supportEmail } from '../../consts';

export default function Support() {
  const { t, i18n } = useTranslation();

  return (
    <Page className="page support">
      <Header/>
      <div className="banner">
        <h1>{t('nav_support')}</h1>
      </div>

      <section className="s1">
        <Wrapper>
          <div className="text">
            <h1>{t('support_faq_title')}</h1>
            <Trans
              i18nKey="support_faq_list"
              components={{
                br: <br/>,
                dl: <dl/>,
                dt: <dt/>,
                dd: <dd/>,
              }}
            />
            <dl>
              <dt>{t('support_faq_qLast')}</dt>
              <dd><Trans i18nKey='support_faq_aLast' components={{email: <a href={`mailto:${supportEmail}`}/>}}/></dd>
            </dl>
          </div>
        </Wrapper>
      </section>

      <Footer/>
    </Page>
  );
};