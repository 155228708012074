import './Dialog.scss';
import React from 'react';
import closeIcon from '../../assets/close.svg';
import { ReactSVG } from 'react-svg';

function Dialog(props) {
  return (
    <dialog className="widget dialog">
      <div className="body">
        <a className="close" href="javascript:void(0);" onClick={props.onClose}>
          {props.closeElement ? props.closeElement : <ReactSVG src={closeIcon}/>}
        </a>
        {props.children}
      </div>
    </dialog>
  );
}

export default Dialog;
