import './App.scss';
import React, { useContext, useEffect, useState } from 'react';
import {HashRouter as Router, Route, Switch} from 'react-router-dom';
import Home from './pages/home/Home';
import Plus from './pages/plus/Plus';
import Support from './pages/support/Support';
import Providers from './pages/providers/Providers';
import Dialog from './components/dialog/Dialog';
import Login from './components/login/Login';
// import User from './pages/user/User';
import Context from './context';
import i18n from './i18n/i18n';
// import Dashboard from './pages/user/Dashboard';
// import Plans from './pages/user/Plans';

function App() {
  const {isShowLogin, isShowReg, hideLogin} = useContext(Context);

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/plus" component={Plus} />
        <Route exact path="/support" component={Support} />
        <Route exact path="/providers" component={Providers} />
        {/* <Route path="/user" render={() => <User>
          <Route exact path="/user" component={Dashboard}/>
          <Route exact path="/user/plans" component={Plans}/>
        </User>}/> */}
      </Switch>
      {isShowLogin ? <Dialog onClose={() => hideLogin()}><Login isReg={isShowReg}/></Dialog> : null}
    </Router>
  );
}

export default App;