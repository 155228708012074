import { supportEmail } from "../consts";

export default {
  lng: '繁體中文',
  nav_home: '首頁',
  nav_support: '支援',
 
  link_faq: '常見問題',
 
  btn_learn_more: '瞭解更多',
  btn_send_email: '發送郵件',
  btn_login: '登入',
  btn_logout: '登出',
  btn_reg: '創建賬戶',
  btn_renew: '續費',
  btn_choose_plan: '選擇套餐',

  login_title: '登入',
  login_label_username: '賬戶',
  login_label_password: '密碼',
  
  reg_title: '創建賬戶',
  reg_tips: '我們暫停了Web頁面的註冊功能，稍後將恢復。你可以下載OneClick，並在App中創建賬戶。',
  
  home_s1_title: '<small>自由訪問互聯網，</small><br/>安全、易用，還免費',
  home_s1_p1: '支援時下最流行的加密協議：<ss>Shadowsocks</ss>、<trojan>Trojan</trojan>、<vmess>Vmess</vmess> 等',
  home_s1_p2: '簡單操作，即可連接、導入或分享你的伺服器',
  home_s1_p3: '在你的iOS和Android設備上免費使用',
  home_s1_p4: '隨時升級到OneClick+，享用我們的VPN服務',

  home_s2_title: '先進、安全和穩定的<br/><strong>VPN服務</strong>',
  home_s2_p1: '連接中國的VPN服務器，使用中國的視頻、音樂和遊戲軟體',
  home_s2_p1_tips: '* 我們稍後會提供其他國家和地區的伺服器',
  home_s2_p2: '全程加密傳輸，保護你的隱私安全',
  home_s2_p3: '無限帶寬，無限速度',
  home_s2_p4: '每月高達500GB的數據流量',
  home_s2_p5: '直接在OneClick中免費試用或訂購服務',

  home_s3_title: '聯繫我們',
  
  plus_welcome: '歡迎回來，',
  plus_login_tips: '已經有賬戶了？',
  plus_goto_dashboard: '轉到賬戶頁面',
  plus_s1_title: '中文應用，盡情享用',
  plus_s1_title_tips: '* 我們稍後會提供其他國家和地區的伺服器',

  plus_s2_title: '低至$4.99每月',
  plus_s2_title_tips: '新用戶專享7天免費試用',

  support_faq_title: '常見問題',
  support_faq_list: `
    <dl>
      <dt>OneClick是什麼？</dt>
      <dd>OneClick是一個免費應用，可以幫助你通過Shadowsocks、Vmess、Trojan等加密協議不受限制的訪問互聯網。</dd>
    </dl>
    <dl>
      <dt>OneClick支援哪些设备？</dt>
      <dd>目前支援iOS和Android设备。</dd>
    </dl>
    <dl>
      <dt>OneClick提供加密協議所需的伺服器嗎？</dt>
      <dd>你需要自備伺服器。</dd>
    </dl>
  `,
  support_faq_qLast: '還有其他問題？',
  support_faq_aLast: `請聯繫 <email>${supportEmail}</email>。`,

  user_title: '賬戶',
  user_tab_dashboard: '控制台',
  user_tab_plans: '選擇套餐',

  user_dashboard_account_title: '賬戶信息',
  user_dashboard_plan_title: '你的套餐',
  user_dashboard_plan_empty: '你還沒有訂購套餐',
  user_dashboard_plan_summary: '你有一個有效的套餐，將於 {{remainDays}} 天後過期',
  user_dashboard_plan_expire_on: '有效期至',
  user_dashboard_plan_data_usage: '數據用量',
  user_dashboard_plan_data_reset: '數據用量將於 {{resetDate}} 重置',
  user_dashboard_plan_subscription_title: '訂閱地址',
  user_dashboard_plan_subscription_tips: '你可以將訂閱地址粘貼到任何你喜歡的支持訂閱的App中使用OneClick+服务。',
  user_dashboard_plan_subscription_reset: '如果你懷疑訂閱地址遭洩漏，請<reset>重置</reset>，原地址將立即失效。',
  user_dashboard_plan_subscription_copied: '已復制',
  user_dashboard_plan_subscription_resetConfirm: '重置後，原訂閱地址將失效，請注意更換。是否繼續？',
  user_dashboard_stat_title: '流量統計',

  user_plans_tips: '我們暫停了Web頁面的訂購功能，稍後將恢復。<br/>你可以下載OneClick，在App中登錄你的賬戶後訂購服務。',

  provider_title: '選擇服務提供商',
  provider_more: '更多',
  provider_biz: '服務商合作',
}