import { supportEmail } from "../consts";

export default {
  lng: '简体中文',
  nav_home: '首页',
  nav_support: '支持',

  link_faq: '常见问题',

  btn_learn_more: '了解更多',
  btn_send_email: '发送邮件',
  btn_login: '登录',
  btn_logout: '退出',
  btn_reg: '创建账户',
  btn_renew: '续费',
  btn_choose_plan: '选择套餐',

  login_title: '登录',
  login_label_username: '账户',
  login_label_password: '密码',

  reg_title: '创建账户',
  reg_tips: '我们暂停了Web页面的注册功能，稍后将恢复。你可以下载OneClick，并在App中创建账户。',

  home_s1_title: '<small>自由访问互联网，</small><br/>安全、易用，还免费',
  home_s1_p1: '支持时下最流行的加密协议：<ss>Shadowsocks</ss>、<trojan>Trojan</trojan>、<vmess>Vmess</vmess> 等',
  home_s1_p2: '简单操作，即可连接、导入或分享你的服务器',
  home_s1_p3: '在你的iOS和Android设备上免费使用',
  home_s1_p4: '随时升级到OneClick+，享用我们提供的VPN服务',

  home_s2_title: '先进、安全和稳定的 <br/><strong>VPN服务</strong>',
  home_s2_p1: '连接中国的VPN服务器，使用中国的视频、音乐和游戏应用',
  home_s2_p1_tips: '* 我们稍后会提供其他国家和地区的服务器',
  home_s2_p2: '全程加密传输，保护你的隐私安全',
  home_s2_p3: '无限带宽，无限速度',
  home_s2_p4: '每月高达500GB的数据流量',
  home_s2_p5: '直接在OneClick中免费试用或订购服务',

  home_s3_title: '联系我们',

  plus_welcome: '欢迎回来，',
  plus_login_tips: '已经有账户了？',
  plus_goto_dashboard: '转到账户页面',
  plus_s1_title: '中文应用，尽情享用',
  plus_s1_title_tips: '* 我们稍后会提供其他国家和地区的服务器',
  
  plus_s2_title: '低至$4.99每月',
  plus_s2_title_tips: '新用户专享7天免费试用',

  support_faq_title: '常见问题',
  support_faq_list: `
    <dl>
      <dt>OneClick是什么？</dt>
      <dd>OneClick是一个免费应用，可以帮助你通过Shadowsocks、Vmess、Trojan等加密协议不受限制的访问互联网。</dd>
    </dl>
    <dl>
      <dt>OneClick支持哪些设备？</dt>
      <dd>目前支持iOS和Android设备。</dd>
    </dl>
    <dl>
      <dt>OneClick提供加密协议所需的服务器吗？</dt>
      <dd>你需要自备服务器。</dd>
    </dl>
  `,
  support_faq_qLast: '还有其他问题？',
  support_faq_aLast: `请联系 <email>${supportEmail}</email>。`,

  user_title: '账户',
  user_tab_dashboard: '控制台',
  user_tab_plans: '选择套餐',

  user_dashboard_account_title: '账户信息',
  user_dashboard_plan_title: '你的套餐',
  user_dashboard_plan_empty: '你还没有订购套餐',
  user_dashboard_plan_summary: '你有一个有效的套餐，将于 {{remainDays}} 天后过期',
  user_dashboard_plan_expire_on: '有效期至',
  user_dashboard_plan_data_usage: '数据用量',
  user_dashboard_plan_data_reset: '数据用量将于 {{resetDate}} 重置',
  user_dashboard_plan_subscription_title: '订阅地址',
  user_dashboard_plan_subscription_tips: '你可以将订阅地址粘贴到任何你喜欢的支持订阅的App中使用OneClick+服务。',
  user_dashboard_plan_subscription_reset: '如果你怀疑订阅地址遭泄漏，请<reset>重置</reset>，原地址将立即失效。',
  user_dashboard_plan_subscription_copied: '已复制',
  user_dashboard_plan_subscription_resetConfirm: '重置后，原订阅地址将失效，请注意更换。是否继续？',
  user_dashboard_stat_title: '流量统计',

  user_plans_tips: '我们暂停了Web页面的订购功能，稍后将恢复。<br/>你可以下载OneClick，在App中登录你的账户后订购服务。',

  provider_title: '选择服务提供商',
  provider_more: '更多',
  provider_biz: '服务商合作',
}