import './Footer.scss';
import React from 'react';
import Wrapper from '../wrapper/Wrapper';
import logo from '../../assets/logo@2x.png';

function Footer(props) {
  return (
    <footer className="widget footer">
      <Wrapper>
        <img className="logo" src={logo}/>
        <div className="text">
          <p>OneClick.earth</p>
          <p className="copyright">&copy;2021 We Mum Pty Ltd. All rights reserved.</p>
        </div>
      </Wrapper>
    </footer>
  );
}

export default Footer;
