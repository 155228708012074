import React, { useEffect, useState } from 'react';
import { getUserInfo, login } from './apis/apis';
import cookie from 'js-cookie';
import { createHashHistory } from 'history';

const Context = React.createContext({
  hasToken: false,
  user: null,
  isLogin: false,
  isShowLogin: false,

  showLogin: () => {},
  hideLogin: () => {},
  login: async () => {},

  redirect: () => {},
});

export default Context;

export function Provider(props) {
  const [isShowLogin, setIsShowLogin] = useState(false);
  const [isShowReg, setIsShowReg] = useState(false);
  const [user, setUser] = useState(false);

  const state = {
    hasToken: !!cookie.get('authorization'),
    user,
    isShowLogin,
    isShowReg,

    showLogin: (isReg) => {
      setIsShowLogin(true);
      setIsShowReg(isReg);
    },

    hideLogin: () => {
      setIsShowLogin(false);
      setIsShowReg(false);
    },

    login: async (username, password) => {
      await login(username, password);
      const user = await getUserInfo();
      setUser(user);
    },

    logout: () => {
      cookie.remove('authorization', '');
      setUser(null);
    },

    redirect: path => {
      createHashHistory().push(path);
    }
  };

  // useEffect(async () => {
  //   if (state.hasToken && !state.user) {
  //     const user = await getUserInfo();
  //     setUser(user);
  //   }
  // });

  return (
    <Context.Provider value={state}>{props.children}</Context.Provider>
  )
}