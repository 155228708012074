import React, { useEffect } from 'react';

function Page(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={props.className}>{props.children}</div>
  );
}

export default Page;
