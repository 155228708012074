import './Home.scss';
import React, { useEffect, useState } from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import Wrapper from '../../components/wrapper/Wrapper';
import Download from '../../components/download/Download';

import {Link} from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import pic1 from '../../assets/p1@2x.png';
import pic2 from '../../assets/p2@2x.png';
import plusLogo from '../../assets/plus_logo@2x.png';
import support1 from '../../assets/support_man.svg';
import support2 from '../../assets/support_woman.svg';

import Page from '../../components/page/Page';
import { supportEmail } from '../../consts';

export default function Index() {
  const { t, i18n } = useTranslation();
  const [showDemo1, setShowDemo1] = useState(false);
  const [showDemo2, setShowDemo2] = useState(false);

  return (
    <Page className="page index">
      <Header/>
      {/* <div className="banner">
        <Wrapper>
          <img className="slogan" src={slogan} />
        </Wrapper>
      </div> */}
      <section className={`s1 ${showDemo1 ? 'showDemo' : ''}`}>
        <Wrapper>
          <img className="demo" src={pic1} onClick={() => setShowDemo1(!showDemo1)} />
          <div className="text">
            <h1><Trans i18nKey="home_s1_title" components={{br: <br/>, small: <b className="small"/>}}/></h1>
            <p><Trans i18nKey="home_s1_p1" components={{br: <br/>, ss: <b className="ss"/>, trojan: <b className="trojan"/>, vmess: <b className="vmess"/>}}/></p>
            <p><Trans i18nKey="home_s1_p2" components={{b: <span/>}}/></p>
            <p><Trans i18nKey="home_s1_p3" components={{b: <span/>}}/></p>
            {/* <p><Trans i18nKey="home_s1_p4" components={{b: <span/>}}/></p> */}
            <Download/>
          </div>
        </Wrapper>
      </section>
      {/* <section className={`s2 ${showDemo2 ? 'showDemo' : ''}`}>
        <Wrapper>
          <img className="demo" src={pic2} onClick={() => setShowDemo2(!showDemo2)} />
          <div className="text">
            <img className="plusLogo" src={plusLogo}/>
            <h1><Trans i18nKey="home_s2_title" components={{br: <br/>, strong: <strong/>}}/></h1>
            <p><Trans i18nKey="home_s2_p1" components={{b: <b/>}}/></p>
            <p className="tips"><Trans i18nKey="home_s2_p1_tips"/></p>
            <p><Trans i18nKey="home_s2_p2"/></p>
            <p><Trans i18nKey="home_s2_p3"/></p>
            <p><Trans i18nKey="home_s2_p4"/></p>
            <p><Trans i18nKey="home_s2_p5"/></p>
            <p className="learnMore">
              <Link className="btn" to="/plus">{t('btn_learn_more')}</Link>
            </p>
          </div>
        </Wrapper>
      </section> */}
      <section className="s3">
        <Wrapper>
          <img className="icon" src={support2} />
          <div className="text">
            <h1><b className="small"><Trans i18nKey="home_s3_title"/></b><br/>{supportEmail}</h1>
            <p>
              <a href={`mailto:${supportEmail}`} className="btn">{t('btn_send_email')}</a>
              <Link className="link" to="/support">{t('link_faq')}</Link>
            </p>
          </div>
        </Wrapper>
      </section>
      <Footer/>
    </Page>
  );
};