import { supportEmail } from "../consts";

export default {
  lng: 'English',
  nav_home: 'Home',
  nav_support: 'Support',
  nav_account: 'Account',
  
  link_faq: 'FAQ',

  btn_learn_more: 'Learn more',
  btn_send_email: 'Send email',
  btn_login: 'Sign in',
  btn_logout: 'Sign out',
  btn_reg: 'Create an account',
  btn_renew: 'Renew',
  btn_choose_plan: 'Choose plan',

  login_title: 'Sign in',
  login_label_username: 'Email',
  login_label_password: 'Password',
  
  reg_title: 'Create an account',
  reg_tips: 'We have suspended the registration function of the web page and will resume later, you can download OneClick and create an account in the App.',

  home_s1_title: '<small>Unrestricted access to the Internet</small><br/>Safe, Easy & Free',
  home_s1_p1: 'Support the most popular encryption protocols such as <br/><ss>Shadowsocks</ss>, <trojan>Trojan</trojan>, <vmess>Vmess</vmess>, etc.',
  home_s1_p2: 'Easy to connect, import and share your server',
  home_s1_p3: 'Free to use on iOS and Android devices',
  home_s1_p4: 'Upgrade to OneClick+ anytime to enjoy our VPN service',

  home_s2_title: 'Advanced, safe & stable <br/><strong>VPN service</strong>',
  home_s2_p1: 'Use VPN servers in China to use China\'s video, music apps and games',
  home_s2_p1_tips: '* We will provide servers in other countries and regions later',
  home_s2_p2: 'Encrypted transmission throughout to protect your privacy',
  home_s2_p3: 'Unlimited bandwidth, Unlimited speed',
  home_s2_p4: 'Maximum 500GB data per month',
  home_s2_p5: 'Directly free trial and subscribe in OneClick app',

  home_s3_title: 'Contact us',

  plus_welcome: 'Welcome back, ',
  plus_goto_dashboard: 'Go to Account',
  plus_login_tips: 'Already have a account?',
  plus_s1_title: 'Enjoy China\'s apps & games',
  plus_s1_title_tips: '* We will provide servers in other countries and regions later',

  plus_s2_title: 'From $4.99/mo',
  plus_s2_title_tips: '7-day free trial for new users',

  support_faq_title: 'FAQ',
  support_faq_list: `
    <dl>
      <dt>What is OneClick?</dt>
      <dd>OneClick is a free app that can help you unrestricted access to the Internet via encryption protocol such as Shadowsocks, Vmess and Trojan etc.</dd>
    </dl>
    <dl>
      <dt>What devices does OneClick support?</dt>
      <dd>Currently supports iOS and Android devices.</dd>
    </dl>
    <dl>
      <dt>Does OneClick provide the server required by the encryption protocol?</dt>
      <dd>You need to prepare your own server.</dd>
    </dl>
  `,
  support_faq_qLast: 'Any other questions?',
  support_faq_aLast: `Please email to <email>${supportEmail}</email>.`,

  user_title: 'Account',
  user_tab_dashboard: 'Dashboard',
  user_tab_plans: 'Choose Plan',

  user_dashboard_account_title: 'Basic Infomation',
  user_dashboard_plan_title: 'Your Plan',
  user_dashboard_plan_empty: 'You haven\'t ordered any plans',
  user_dashboard_plan_summary: 'You have a valid plan that will expire after {{remainDays}} day(s)',
  user_dashboard_plan_expire_on: 'Expire on',
  user_dashboard_plan_data_usage: 'Data usage',
  user_dashboard_plan_data_reset: 'Data usage will be reset on {{resetDate}}',
  user_dashboard_plan_subscription_title: 'Subscription URL',
  user_dashboard_plan_subscription_tips: 'You can paste the URL into any of your favorite subscription-supported apps to use OneClick+ service.',
  user_dashboard_plan_subscription_reset: 'If you suspect that the URL has leaked accidentally, please <reset>reset it</reset>, and the original URL will be invalid immediately.',
  user_dashboard_plan_subscription_copied: 'Copied',
  user_dashboard_plan_subscription_resetConfirm: 'After resetting, the original subscription URL will become invalid, please pay attention to replacement. Whether to continue?',
  user_dashboard_stat_title: 'Usage Statistics',

  user_plans_tips: 'We suspended the ordering function of the web page and will resume it later. <br/>You can download OneClick, sign in to your account in the app and order the service.',

  provider_title: 'Choose a provider',
  provider_more: 'Show more',
  provider_biz: 'Business Cooperation',
}