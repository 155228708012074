import './Providers.scss';
import React, { useEffect, useState } from 'react';
import cookie from 'js-cookie';
import fanwallImg from '../../assets/providers/fanwall@2x.png';
import moreImg from '../../assets/providers/more.svg';
import { useLocation } from 'react-router-dom';
import { changeLanguage } from '../../i18n/i18n';
import { useTranslation } from 'react-i18next';

function open(url, stat = true) {
  stat && window.gtag('event', 'click_provider', {
    event_label: url
  });
  window.jsbridge.invoke('openInBrowser', {url});
}

function Providers() {
  const {t} = useTranslation();
  const location = useLocation();
  // const [isFold, setIsFold] = useState(true);
  const [uaInfo, setUaInfo] = useState('');

  useEffect(() => {
    window.gtag('event', 'visit_provider');
    const lang = new URLSearchParams(location.search).get('lang');
    if (lang) {
      changeLanguage(lang.toLocaleLowerCase());
    }
  
    document.title = t('provider_title');

    if (cookie.get('OC-IO')) {
      setUaInfo(cookie.get('OC-IO'));
    } else if (cookie.get('UA-INFO')) {
      setUaInfo(cookie.get('UA-INFO'));
    }
  }, []);

  return <div className="page providers">
    <ul>
      <li className="provider" onClick={() => open(`https://fw321.xyz/?u=${uaInfo ? encodeURIComponent(uaInfo.replace('"rkey"', '"ok"').replace('"rdata"', '"od"')) : ''}`)}>
        <i className="icon"><img src={fanwallImg}/></i>
        <dl>
          <dt>FanWall <span className="tag sale">限时折扣</span></dt>
          <dd>海外专业公司运营，Trojan协议，安全支付</dd>
        </dl>
      </li>
      {
        // isFold ? (
        //   <li className="fold" onClick={() => setIsFold(false)}>
        //     {t('provider_more')} <img src={moreImg}/>
        //   </li>
        // ) : (
        //   <>
        //   <li className="provider" onClick={() => open('https://fastlink.ws/auth/register?code=Lcxe')}>
        //     <dl>
        //       <dt>Fastlink</dt>
        //       <dd>https://fastlink.ws</dd>
        //     </dl>
        //   </li>
        //   <li className="provider" onClick={() => open('https://speedpie.net')}>
        //     <dl>
        //       <dt>PieCloud</dt>
        //       <dd>https://speedpie.net</dd>
        //     </dl>
        //   </li>
        //   </>
        // )
      }
    </ul>
    <p className="biz">
      <strong>{t('provider_biz')}</strong>
      <span onClick={() => open('mailto:business@oneclick.earth', false)}>business@oneclick.earth</span>
    </p>
  </div>
}

export default Providers;