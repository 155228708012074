import { get, post } from "./request";
import cookie from 'js-cookie';
import moment from 'moment';

const CODE_SUCCESS = 0;

export async function login(username, password) {
  const resp = await post('/login/email', null, {email: username, pwd: password});
  const {code, data} = resp.data;
  await check(code);
  cookie.set('authorization', data.token);
  return data;
}

export async function getUserInfo() {
  const resp = await get('/user/info');
  const {code, data} = resp.data;
  await check(code);
  return data;
}

export async function resetSubscriptionUrl() {
  const resp = await post('/user/plan/reset');
  const {code, data} = resp.data;
  await check(code);
  return data;
}

export async function getState() {
  const resp = await get('/user/plan/state', {
    startTime: moment().subtract(1, 'months').toDate().getTime(),
    endTime: Date.now(),
  });
  const {code, data} = resp.data;
  await check(code);
  return data;
}

async function check(code) {
  if (code !== CODE_SUCCESS) {
    // throw new Error(code);
    console.error(code);
  }
}