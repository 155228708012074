import './Plus.scss';
import React, { useContext } from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import Wrapper from '../../components/wrapper/Wrapper';
import plusLogo from '../../assets/plus_logo_big@2x.png';
import plusAppDemo from '../../assets/plus_apps_demo.png';
import plusPayDemo from '../../assets/plus_pay_demo.png';
import plusDemo from '../../assets/plus_demo.png';
import Download from '../../components/download/Download';
import Page from '../../components/page/Page';
import Context from '../../context';

import { Trans, useTranslation } from 'react-i18next';

export default function Plus() {
  const { t, i18n } = useTranslation();
  const { user, showLogin, redirect } = useContext(Context);

  return (
    <Page className="page plus">
      <Header dark/>
      <div className="banner">
        <img className="logo" src={plusLogo}/>
        {
          user ?
          <p>{t('plus_welcome')}<button className="btn" onClick={() => redirect('/user')}>{t('plus_goto_dashboard')}</button></p>
          :
          <p>
            {t('plus_login_tips')}
            <button className="btn" onClick={() => showLogin()}>{t('btn_login')}</button>
            <button className="btn aside" onClick={() => showLogin(true)}>{t('btn_reg')}</button>
          </p>
        }
      </div>

      <section className="s1">
        <Wrapper>
          <h1>
            <Trans i18nKey="plus_s1_title"/>
            <span><Trans i18nKey="plus_s1_title_tips"/></span>
          </h1>
          <img className="demo" src={plusAppDemo}/>
        </Wrapper>
      </section>

      <section className="s2">
        <Wrapper>
          <h1>
            <Trans i18nKey="plus_s2_title"/>
            <b className="small"><Trans i18nKey="plus_s2_title_tips"/></b>
          </h1>
          <p className="demo">
            <img className="small" src={plusDemo}/>
            <img src={plusPayDemo}/>
          </p>
          <Download center/>
        </Wrapper>
      </section>

      <Footer/>
    </Page>
  );
};