import axios from 'axios';
import cookie from 'js-cookie';
import qs from 'qs';

axios.defaults.baseURL = '/api';

export function setBaseUrl(baseUrl) {
  axios.defaults.baseURL = baseUrl;
}

export function get(api, params) {
  return request(api, {
    method: 'get',
    params,
  });
}

export function post(api, data, params) {
  return request(api, {
    method: 'post',
    data,
    params,
  });
}

export default function request(api, options) {
  // const auth = cookie.get('authorization');

  // if (auth) {
  //   options.headers = Object.assign({
  //     'Authorization': `Bearer ${auth}`,
  //     'UA-INFO': '{"O-T": "iOS","O-D": {"ver": "iOS_1.0.0","rdata": "Xs0HV8db9sAXGggL0f5FVKRjujuIXk5upVf26bVD05HKuDH7HyC8sqqxxfAbPgywMw3jrUOozEyEmF37PcfNE0+atZdDq+8yenKMDCtlcm\/0VEChApERh3tUSIACDT5g","rkey": "SR9o0qET0hClz8zQ6O50+jTGl\/dT6NKFcHFA0Z3hbfDV\/tbBJeWhcsbVvxWqVEM4YFMiDVywM0s+NGJWwgeWl+SbWRTMyXZWLkpCVnHfWE3opewzVxXnhs303fnkmTQUEoxFJkHL5Ox9ckGkp6p4TEgxnt7e8KUka7ld0IIESr9hDCVthAtJKA69gRMR0JoUnfpGDOzCIOkSyOj6jL0SoXkXqmbqfEIZ8ZFmZDSWSxt1roucYjopIXAGL1uTR6l2NowSnT7zsxYNnqFDNmnVFwJtoxu\/GqH2ig9h+e\/n+g56v0AWkkXZGgibrJ0xrfwbuTp9uJTdjq1SdOulh+Cwyg=="},"O-V": "3.3.3","O-B": "4019"}',
  //   }, options.headers);
  // }

  if (options.method.toUpperCase() !== 'GET' && (!options.type || options.type.toUpperCase() !== 'JSON')) {
    options.headers = Object.assign({}, options.headers, {'Content-Type': 'application/x-www-form-urlencoded'});
    options.data = qs.stringify(options.data);
  }

  return axios({
    url: api,
    ...options,
  });
}