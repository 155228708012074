import './Download.scss';
import React from 'react';
import dlApk from '../../assets/apk@2x.png';
import dlAppStore from '../../assets/app_store@2x.png';
import dlGooglePlay from '../../assets/google_play@2x.png';
import QR from 'qrcode.react';
import { Trans, useTranslation } from 'react-i18next';

function Download(props) {
  const urls = {
    apk: 'https://oneclick.earth/files/app-website-release.apk',
    googlePlay: 'https://play.google.com/store/apps/details?id=earth.oneclick',
    appStore: 'https://apps.apple.com/us/app/id1545555197',
    testflight: 'https://testflight.apple.com/join/AeKtEOD3',
  }

  return (
    <>
    <p className={`widget download ${props.center ? 'center' : ''}`}>
      <a href={urls.apk} onClick={() => stat('apk')}><img src={dlApk} /><QR className="qr" value={urls.apk}/></a>
      <a href={urls.googlePlay} onClick={() => stat('googleplay')} target="_blank"><img src={dlGooglePlay} /><QR className="qr" value={urls.googlePlay}/></a>
      <a href={urls.appStore} onClick={() => stat('appstore')} target="_blank"><img src={dlAppStore} /><QR className="qr" value={urls.appStore}/></a>
    </p>
    <div className='chinaTips'>
      <div><strong>iOS用户需要非中国区的Apple ID</strong>，<a href="/help/appleid.html" onClick={() => stat('appleid')} target="_blank">查看注册帮助，仅需10分钟完成注册</a></div>
      <div>登录非中国区App Store后，即可下载<a href={urls.appStore} onClick={() => stat('appstore')} target="_blank">AppStore版本</a>或<a href={urls.testflight} onClick={() => stat('testflight')} target="_blank">TestFlight版本</a></div>
    </div>
    </>
  );

  function stat(what) {
    window.gtag('event', 'click_download', {
      event_label: what
    });
  }
}

export default Download;
