import './Wrapper.scss';
import React from 'react';

function Wrapper(props) {
  return (
    <div className="widget wrapper">{props.children}</div>
  );
}

export default Wrapper;
