import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enUS from './en_us';
import zhCN from './zh_cn';
import zhTW from './zh_tw';

export const languages = {
  en_us: 'en_us',
  zh_cn: 'zh_cn',
  zh_tw: 'zh_tw',
};

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  [languages.en_us]: {
    translation: {
      ...enUS
    }
  },
  [languages.zh_cn]: {
    translation: {
      ...zhCN
    }
  },
  [languages.zh_tw]: {
    translation: {
      ...zhTW
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: navigator.language ? navigator.language.toLowerCase().replace('-', '_') : languages.en_us,
    fallbackLng: languages.en_us,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;

  export function changeLanguage(lang) {
    i18n.changeLanguage(lang);
  }